@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

/*
  商品カテゴリ　カスタム投稿下層
  -------------------------------------*/
#Page.pageCategory .subCategoryWrap01{
  padding-top: 100px;
  padding-bottom: 20px;
}
#Page.pageCategory .subCategoryWrap01 img{
  display: none;
}
#Page.pageCategory .subCategoryWrap02{
  background-color: #f0f0f0;
}

/*hover*/
#Page.pageIndex #MainImg #MainImgInner li .slideText .linkBtn:hover{
  text-decoration: none;
}
#Page.pageIndex #ContBox08 #ContSubBox08 .blogLink li a:hover{
  text-decoration: none;
}



/*
  トップページ
  -------------------------------------*/

/*メインイメージ*/
#Page.pageIndex #MainImg {
  padding-top: 60px;
}
#Page.pageIndex #MainImg #MainImgInner{
  height: auto;
  position: relative;
}
#Page.pageIndex #MainImg #MainImgInner li .slideTextWrap{
  position: relative;
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
}
#Page.pageIndex #MainImg #MainImgInner li .slideText{
  position: absolute;
  z-index: 100;
}
#Page.pageIndex #MainImg #MainImgInner li.slide01 .slideText{
  bottom: 50px;
  left: 15px;
  text-align: left;
}
#Page.pageIndex #MainImg #MainImgInner li.slide02 .slideText{
  top: 20px;
  right: 15px;
  text-align: right;
}
#Page.pageIndex #MainImg #MainImgInner li.slide03 .slideText{
  bottom: 62px;
  left: 15px;
  text-align: left;
}


#Page.pageIndex #MainImg #MainImgInner li .slideText h2 {
  background-color: transparent;
  line-height: 1.4;
  letter-spacing: .04em; 
  font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif; 
  font-size: 29px;
  font-weight: 400;
  color: #b60915;
  position: static;
  padding: 0;
  transform: none;
  display: block;
  text-shadow: #fff 1px 0 30px;
}
#Page.pageIndex #MainImg #MainImgInner li.slide01 .slideText h2{
  text-shadow: #fff 1px 0 30px, #fff 1px 0 30px, #fff 1px 0 30px, #fff 1px 0 30px, #fff 1px 0 30px, #fff 1px 0 30px, #fff 1px 0 30px, #fff 1px 0 30px, #fff 1px 0 30px, #fff 1px 0 30px, #fff 1px 0 30px;
}
#Page.pageIndex #MainImg #MainImgInner li.slide01 .slideText h2,
#Page.pageIndex #MainImg #MainImgInner li.slide03 .slideText h2 {
  text-align: left;
}
#Page.pageIndex #MainImg #MainImgInner li.slide02 .slideText h2 {
  text-align: right;
}
#Page.pageIndex #MainImg #MainImgInner li .slideText .linkBtn{
  display: inline-block;
  font-size: 16px;
  color: #fff;
  padding: 10px 65px 10px 15px;
  margin-top: 5px;
  border-radius: 5px;
}
#Page.pageIndex #MainImg #MainImgInner .tSlider li img{
  width: 100%;
}

/*キャンペーンスライド*/
#Page.pageIndex #MainImg #MainImgInner li.slide03.campaignSlide .slideText{
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 46%);
  text-align: center;
}
#Page.pageIndex #MainImg #MainImgInner li.slide03.campaignSlide .slideText h2{
  text-align: center;
  color: #fc5988;
  font-size: 26px;
  line-height: 1.34;
  margin-bottom: 1px;
}
#Page.pageIndex #MainImg #MainImgInner li.slide03.campaignSlide .slideText .camLabel{
  width: 146px;
  height: 146px;
}
#Page.pageIndex #MainImg #MainImgInner li.slide03.campaignSlide .slideText .linkBtn{
  margin-top: 10px;
  padding: 9px 65px 9px 15px;
}
/*　//キャンペーンスライド*/

/*ページャーアイコン*/
#Page.pageIndex #MainImg .bx-wrapper .bx-pager{
  position: absolute;
  z-index: 100;
  right: 0;
  bottom: 15px;
  left: 0;
  width: 100px;
  height: 10px;
  margin: auto;
}
#Page.pageIndex #MainImg .bx-wrapper .bx-pager-item{
  display: inline-block;
  margin-right: 20px;
}
#Page.pageIndex #MainImg .bx-wrapper .bx-pager-item:last-of-type{
  margin-right: 0;
}
#Page.pageIndex #MainImg .bx-wrapper .bx-pager-item a{
  display: block;
  width: 10px;
  height: 10px;
  background: url(../img/00_slidericon02.png) no-repeat center center;
  background-size: 10px auto;
  text-indent: -9999px;
}
#Page.pageIndex #MainImg .bx-wrapper a.bx-pager-link.active{
  background: url(../img/00_slidericon01.png) no-repeat center center;
  background-size: 10px auto;
}
#Page.pageIndex #MainImg .bx-prev, 
#Page.pageIndex #MainImg .bx-next{
  display: none;
}

/*コンテンツ*/
#Page.pageIndex #ContBox09{
	padding: 30px 15px;
	background-color: #f7eef0;
	a{
		position: relative;
		display: block;
		margin: 0 auto;
		width: 100%;
		height: 90px;
		background-repeat: no-repeat;
		background-position: center center, left top, right 26px center, left top;
		background: #f36e8d; /* Old browsers */
		background: url("../img/contents/00_banner_txt01.png"), url("../img/contents/00_banner_img01.png"), url("../img/contents/00_banner_img02.png"), -moz-linear-gradient(left,  #f36e8d 1%, #a4051a 100%); /* FF3.6-15 */
		background: url("../img/contents/00_banner_txt01.png"), url("../img/contents/00_banner_img01.png"), url("../img/contents/00_banner_img02.png"), -webkit-linear-gradient(left,  #f36e8d 1%,#a4051a 100%); /* Chrome10-25,Safari5.1-6 */
		background: url("../img/contents/00_banner_txt01.png"), url("../img/contents/00_banner_img01.png"), url("../img/contents/00_banner_img02.png"), linear-gradient(to right,  #f36e8d 1%,#a4051a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		background-size: 345px auto, auto 90px, 10px auto, 100% 90px;
		text-align: left;
		text-indent: -9999px;
		&::after{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			box-sizing: border-box;
			border: 4px solid rgba(255, 255, 255, .3);
			width: 100%;
			height: 90px;

		}
	}
}

/*-styleA-*/
#Page.pageIndex #ContBox01.styleA{
  background-color: #f36e8d;
  padding: 30px 15px;
}
#Page.pageIndex #ContBox01.styleA #ContSubBox01{
  background: url(../img/contents/00_limited_bg_a.jpg) no-repeat center bottom;
  background-size: contain;
  border-radius: 5px;
  padding-bottom: 45px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
  background-color: #efdae2;
}
#Page.pageIndex #ContBox01.styleA #ContSubBox01 dt{
  width: 100%;
  display: block;
  position: relative;
  padding-bottom: 5px;
}
#Page.pageIndex #ContBox01.styleA #ContSubBox01 .titleBand{
  width: 315px;
  display: block;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}
#Page.pageIndex #ContBox01.styleA #ContSubBox01 .pushImg{
  width: 100%;
  display: block;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
#Page.pageIndex #ContBox01.styleA #ContSubBox01 .pushText{
  width: 100%;
  display: block;
  position: absolute;
  bottom: -90px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  font-weight: 700;
  color: #a4051a;
  text-align: center;
  line-height: 1.5;
}
#Page.pageIndex #ContBox01.styleA #ContSubBox01 .pushPrice{
  width: 285px;
  display: block;
  margin: 5px auto 0;
}
#Page.pageIndex #ContBox01.styleA #ContSubBox01 dd{
  margin: 95px 0 20px;
  padding: 0 30px;
  text-align: justify;
  line-height: 1.85;
  font-size: 16px;
}
/*-styleB-*/
#Page.pageIndex #ContBox01.styleB{
  display: none;
}
#Page.pageIndex #ContBox01.styleB{
  background-color: #f0f0f0;
  padding: 30px 15px;
  border-bottom: 1px solid #f36e8d;
}
#Page.pageIndex #ContBox01.styleB #ContSubBox01{
  background: url(../img/contents/00_limited_bg_b.jpg) no-repeat center bottom;
  background-size: contain;
  border-radius: 5px;
  padding-bottom: 45px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
  border: 1px solid #fff;
  background-color: #a4051a;
}
#Page.pageIndex #ContBox01.styleB #ContSubBox01 dt{
  width: 100%;
  display: block;
  position: relative;
  padding-bottom: 5px;
}
#Page.pageIndex #ContBox01.styleB #ContSubBox01 .titleBand{
  width: 315px;
  display: block;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}
#Page.pageIndex #ContBox01.styleB #ContSubBox01 .pushImg{
  width: 100%;
  display: block;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
#Page.pageIndex #ContBox01.styleB #ContSubBox01 .pushText{
  width: 100%;
  display: block;
  position: absolute;
  bottom: -90px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  line-height: 1.5;
}
#Page.pageIndex #ContBox01.styleB #ContSubBox01 .pushPrice{
  width: 285px;
  display: block;
  margin: 5px auto 0;
}
#Page.pageIndex #ContBox01.styleB #ContSubBox01 dd{
  margin: 95px 0 20px;
  padding: 0 30px;
  text-align: justify;
  line-height: 1.85;
  color: #fff;
  font-size: 16px;
}


#Page.pageIndex #ContBox02{
  background-color: #f7eef0;
  padding: 30px 15px 45px;
  /*border-top: 1px solid #f36e8d;*/
}
#Page.pageIndex #ContBox02 .camBtn{
  margin-bottom: 10px;
  padding: 5px;
  display: block;
  /*background: rgb(255,105,163);
  background: linear-gradient(335deg, rgba(255,105,163,1) 0%, rgba(255,88,118,1) 100%);*/
  background: rgb(248,111,100);
  background: linear-gradient(335deg, rgba(248,111,100,1) 0%, rgba(253,84,146,1) 100%);
  position: relative;
}
#Page.pageIndex #ContBox02 .camBtn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /*border: 20px solid #fff;*/
  width: 100%;
  height: 100%;
  background-size: auto auto;
  background-color: rgba(0, 0, 0, 0);
  background-image: repeating-linear-gradient(135deg, transparent, transparent 15px, rgba(255, 255, 255, 0.07) 15px, rgba(255, 255, 255, 0.07) 30px );
}
#Page.pageIndex #ContBox02 .camBtn:hover{
  text-decoration: none;
}
#Page.pageIndex #ContBox02 .camBtn .camBtnInner{
  padding: 10px 0;
  border: 4px solid #fe8ab0;
  background: url(../img/00_arrow_white.png) no-repeat right 10px center;
  background-size: 40px auto;
}
#Page.pageIndex #ContBox02 .camBtn .hTitle{
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.2em;
  line-height: 1.5;
}
#Page.pageIndex #ContBox02 .camBtn .enTitle{
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0.4em;
  margin-left: 10px;
}

/* トップページ　キャンペーン追加 */
#Page.pageIndex #Main #ContBox02 {
  padding-top: 30px;
  padding-bottom: 55px;
}
#Page.pageIndex #Main #ContBox02 .post {
  margin-top: 34px;
}

#Page.pageIndex #Main #ContBox02 .post_item {
  background-color: #fff;
}
#Page.pageIndex #Main #ContBox02 .post_item a{
  display: block;
  padding: 30px 15px;
}
#Page.pageIndex #Main #ContBox02 .post_item a:hover{
  text-decoration: none;
}
#Page.pageIndex #Main #ContBox02 .post_item .labelBox {
  justify-content: left;
  margin-bottom: 11px;
}
#Page.pageIndex #Main #ContBox02 .post_item .labelBox {
  display: flex;
  flex-wrap: wrap;
  margin: -5px 0 0 -5px;
}
#Page.pageIndex #Main #ContBox02 .post_item .labelBox .label {
  background-color: #a4051a;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.2em;
  line-height: 24px;
  padding: 1px 0.6333em 0 0.79166em;
  margin: 5px 0 0 5px;
}
#Page.pageIndex #Main #ContBox02 .post_item .title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.05em;
  line-height: 27px;
  margin-bottom: 7px;
}
#Page.pageIndex #Main #ContBox02 .post_item time {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: #a4051a;
  letter-spacing: 0.05em;
  margin-bottom: 12px;
}
#Page.pageIndex #Main #ContBox02 .post_item .thumb {
  margin-bottom: 30px;
}
#Page.pageIndex #Main #ContBox02 .post_item .thumb img {
  width: 100%;
}
#Page.pageIndex #Main #ContBox02 .post_item .moreLinkBtn {
  display: inline-block;
  width: 195px;
  //width: auto;
  height: 40px;
  background: #f46082 url(../img/00_arrow_white.png) no-repeat right 15px top 17px;
  background-color: #f46082;
  background-size: auto 4px;
  font-size: 14px;
  letter-spacing: 0.2em;
  line-height: 40px;
  text-decoration: none;
  padding: 0 25px 0 25px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  //font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
}
#Page.pageIndex #Main #ContBox02 .moreLinkBtn.toCat {
  display: inline-block;
  //width: 195px;
  width: auto;
  height: 40px;
  //background: #f46082 url(../img/00_arrow_white.png) no-repeat right 15px top 17px;
  background-color: #f46082;
  background-size: auto 4px;
  font-size: 14px;
  letter-spacing: 0.2em;
  line-height: 40px;
  text-decoration: none;
  padding: 0 25px 0 25px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
  margin-top: 30px;
}
#Page.pageIndex #Main #ContBox02 .post_item + .post_item {
  margin-top: 30px;
}



/*#Page.pageIndex #ContBox02 .camBnr li{
  margin-bottom: 10px;
}
#Page.pageIndex #ContBox02 .camBnr li:last-of-type{
  margin-bottom: 0;
}
#Page.pageIndex #ContBox02 .camBnr li a{
  display: flex;
  align-items: center;
  background-color: #fff;
}
#Page.pageIndex #ContBox02 .camBnr li a:hover{
  text-decoration: none;
}
#Page.pageIndex #ContBox02 .camBnr li figure{
  min-width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#Page.pageIndex #ContBox02 .camBnr li:nth-of-type(1) figure{
  background: url(../img/00_cam_img01_2.jpg);
}
#Page.pageIndex #ContBox02 .camBnr li:nth-of-type(2) figure{
  background: url(../img/00_cam_img02_2.jpg);
}
#Page.pageIndex #ContBox02 .camBnr li:nth-of-type(1) img{
  width: 20px;
  display: inline-block;
}
#Page.pageIndex #ContBox02 .camBnr li:nth-of-type(2) img{
  width: 45px;
  display: inline-block;
}
#Page.pageIndex #ContBox02 .camBnr dl{
  display: inline-block;
  width: 100%;
  padding: 15px 15px;
}
#Page.pageIndex #ContBox02 .camBnr .camTitle{
  font-size: 16px;
  font-weight: 700;
}
#Page.pageIndex #ContBox02 .camBnr .camCont{
  font-size: 14px;
  text-align: justify;
}*/

#Page.pageIndex #ContBox03{
  background: url(../img/contents/00_about_bg.jpg) no-repeat center top;
  background-size: cover;
  padding: 37px 15px 45px;
}
#Page.pageIndex #ContBox03 .hTitle{
  color: #fff;
}
#Page.pageIndex #ContBox03 .enTitle{
  color: #f36e8d;
}
#Page.pageIndex #ContBox03 #ContSubBox03{
  background-color: #fff;
  padding: 40px 25px;
  margin-top: 20px;
}
#Page.pageIndex #ContBox03 #ContSubBox03 .leadTitle{
  margin-bottom: 12px;
}
#Page.pageIndex #ContBox03 #ContSubBox03 p{
  margin-bottom: 20px;
}

#Page.pageIndex #ContBox04{
  background-color: #f0f0f0;
  padding: 38px 0 0;
}
#Page.pageIndex #ContBox04 .hTitle{
  margin-bottom: 20px;
}
#Page.pageIndex #ContBox04 #ContSubBox04 .post .post_wrap{
  letter-spacing: -1em;
}
#Page.pageIndex #ContBox04 #ContSubBox04 .post .post_wrap::after{
  content: "";
  clear: both;
  display: block;
}
#Page.pageIndex #ContBox04 #ContSubBox04 .post .post_item{
  width: 50%;
  display: inline-block;
  letter-spacing: 0em;
  vertical-align: middle;
  border-bottom: 1px solid #f0f0f0;
}
#Page.pageIndex #ContBox04 #ContSubBox04 .post .post_item:nth-of-type(odd){
  float: left;
  /*border-right: 1px solid #f0f0f0;*/
}
#Page.pageIndex #ContBox04 #ContSubBox04 .post .post_item:nth-of-type(even){
  /*border-left: 1px solid #f0f0f0;*/
  float: right;
  position: relative;
}
#Page.pageIndex #ContBox04 #ContSubBox04 .post .post_item:nth-of-type(even)::after{
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  border-left: 1px solid #f0f0f0;
  position: absolute;
  top: 0;
  left: 0;
}
#Page.pageIndex #ContBox04 #ContSubBox04 .post .post_item a{
  display: block;
}
#Page.pageIndex #ContBox04 #ContSubBox04 .post .post_item a .catImg{
  width: 100%;
}
#Page.pageIndex #ContBox04 #ContSubBox04 .post .post_item a .catTitle{
  background-color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  line-height: 1.4;
  letter-spacing: 0.04em
}

#Page.pageIndex #ContBox05{
  background-color: #f0f0f0;
  padding: 35px 15px 45px;
}
#Page.pageIndex #ContBox05 #ContSubBox05{
  margin-top: 20px;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .step{
  background-color: #fff;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .step h4{
  font-size: 16px;
  font-weight: 700;
  padding: 18px 15px 18px 70px;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .morningStep{
  margin-bottom: 10px;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .morningStep h4.click{
  background: url(../img/00_plus_red.png) no-repeat right 15px center, url(../img/00_ico_morning.png) no-repeat left 18px center;
  background-size: 20px auto, 38px auto;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .nightStep h4.click{
  background: url(../img/00_plus_red.png) no-repeat right 15px center, url(../img/00_ico_night.png) no-repeat left 22px center;
  background-size: 20px auto, 32px auto;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .morningStep h4.click.active{
  background: url(../img/00_minus_red.png) no-repeat right 15px center, url(../img/00_ico_morning.png) no-repeat left 18px center;
  background-size: 20px auto, 38px auto;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .nightStep h4.click.active{
  background: url(../img/00_minus_red.png) no-repeat right 15px center, url(../img/00_ico_night.png) no-repeat left 22px center;
  background-size: 20px auto, 32px auto;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .step .showBox{
  padding: 25px 15px 21px;
  border-top: 1px solid #f0f0f0;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .step{
  width: 100%;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .step dl::after{
  content: "";
  display: block;
  clear: both;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .step dl dt{
  width: calc(100% - 90px);
  position: relative;
  padding: 15px 10px 15px 48px;
  display: inline-block;
  float: left;
  font-size: 16px;
  font-weight: 700;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .step dl dd{
  display: inline-block;
  float: right;
  padding: 10px 0 10px;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .step dl dt::before{
  display: block;
  content: "";
  width: 40px;
  height: 55px;
  position: absolute;
  top: -3px;
  left: 0;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .step dl:nth-of-type(1) dt::before{
  background: url(../img/contents/00_step01.png) no-repeat center top;
  background-size: 40px auto;
  top: -9px;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .step dl:nth-of-type(2) dt::before{
  background: url(../img/contents/00_step02.png) no-repeat center top;
  background-size: 40px auto;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .step dl:nth-of-type(3) dt::before{
  background: url(../img/contents/00_step03.png) no-repeat center top;
  background-size: 40px auto;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .step dl:nth-of-type(4) dt::before{
  background: url(../img/contents/00_step04.png) no-repeat center top;
  background-size: 40px auto;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .step dl:nth-of-type(5) dt::before{
  background: url(../img/contents/00_step05.png) no-repeat center top;
  background-size: 40px auto;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .step dl:nth-of-type(6) dt::before{
  background: url(../img/contents/00_step06.png) no-repeat center top;
  background-size: 40px auto;
}
#Page.pageIndex #ContBox05 #ContSubBox05 .step .detailLinkBtn{
  width: 90px;
}

#Page.pageIndex #ContBox06{
  border-bottom: 1px solid #e1e1e1;
  padding: 37px 15px 45px;
}
#Page.pageIndex #ContBox06 #ContSubBox06{
  margin-top: 20px;
}
#Page.pageIndex #ContBox06 #ContSubBox06 .post_wrap{
  margin-bottom: 30px;
}
#Page.pageIndex #ContBox06 #ContSubBox06 .post .post_item{
  border-bottom: 1px solid #e1e1e1;
}
#Page.pageIndex #ContBox06 #ContSubBox06 .post .post_item:first-of-type{
  border-top: 1px solid #e1e1e1;
}
#Page.pageIndex #ContBox06 #ContSubBox06 .post .post_item a{
  padding: 26px 0 31px;
  display: flex;
  background: url(../img/00_arrow_red.png) no-repeat right bottom 20px;
  background-size: 40px auto;
}
#Page.pageIndex #ContBox06 #ContSubBox06 .post .post_item a figure {
  margin-right: 15px
}
#Page.pageIndex #ContBox06 #ContSubBox06 .post .post_item a .thumb{
  width: 125px;
  margin-top: 5px;
}
#Page.pageIndex #ContBox06 #ContSubBox06 .post .post_item a .time{
  color: #888888;
  font-size: 14px;
  letter-spacing: 0.2em;
  margin-bottom: 5px;
}
#Page.pageIndex #ContBox06 #ContSubBox06 .post .post_item a .title{
  line-height: 1.8;
  font-size: 16px;
}

#Page.pageIndex #ContBox07{
  padding: 37px 15px 45px;
}
#Page.pageIndex #ContBox07 #ContSubBox07{
  margin-top: 20px;
}
#Page.pageIndex #ContBox07 #ContSubBox07 .post_wrap{
  margin-bottom: 30px;
}
#Page.pageIndex #ContBox07 #ContSubBox07 .post .post_item{
  padding: 25px 0;
  border-bottom: 1px solid #e1e1e1;
}
#Page.pageIndex #ContBox07 #ContSubBox07 .post .post_item:first-of-type{
  border-top: 1px solid #e1e1e1;
}
#Page.pageIndex #ContBox07 #ContSubBox07 .post .post_item a{
  display: flex;
  background: url(../img/00_arrow02_red.png) no-repeat right 5px center;
  background-size: 10px auto;
  padding-right: 20px;
}
#Page.pageIndex #ContBox07 #ContSubBox07 .post .post_item a .time{
  color: #888888;
  font-size: 14px;
  letter-spacing: 0.2em;
}
#Page.pageIndex #ContBox07 #ContSubBox07 .post .post_item a .title{
  line-height: 1.8;
  font-size: 16px;
}


#Page.pageIndex #ContBox08{
  background-color: #f0f0f0;
  padding: 30px 15px;
}
#Page.pageIndex #ContBox08 #ContSubBox08 .blogLink{

}
#Page.pageIndex #ContBox08 #ContSubBox08 .blogLink li a{
  background-color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 23px 15px 23px 90px;
  margin-bottom: 10px;
  display: block;
}
#Page.pageIndex #ContBox08 #ContSubBox08 .blogLink li:last-of-type a{
  margin-bottom: 0;
}

#Page.pageIndex #ContBox08 #ContSubBox08 .blogLink_voice a{
  background: url(../img/00_ico_voice.png) no-repeat left 15px center, url(../img/00_arrow02_red.png) no-repeat right 15px center;
  background-size: 59px auto, 10px auto;
}
#Page.pageIndex #ContBox08 #ContSubBox08 .blogLink_faq a{
  background: url(../img/00_ico_faq.png) no-repeat left 15px center, url(../img/00_arrow02_red.png) no-repeat right 15px center;
  background-size: 53px auto, 10px auto;
}


/*
  ヴィーヴィック化粧品とはページ
  -------------------------------------*/
/*メインイメージ*/
#PageAbout.pageIndex #MainImg #MainImgInner{
  height: 170px;
  width: 100%;
  /*background: url(../img/contents/01_00_title_bg.jpg) no-repeat center bottom;
  background-size: cover;*/
  /*background: url(../img/contents/00_about_bg.jpg) no-repeat center bottom;
  background-size: cover;*/
  background: url(../img/contents/01_00_title_bg03.png) no-repeat center center;
  background-size: cover;
}
#PageAbout.pageIndex #MainImg #MainImgInner h2{
  color: #fff;
  background-color: transparent;
}
#PageAbout.pageIndex #MainImg #MainImgInner .enTitle{
  color: #f36e8d;
}
/*コンテンツ*/
#PageAbout.pageIndex #ContBox01 .leadTitle{
  background: url(../img/contents/01_00_lead_bg.jpg) no-repeat right top;
  background-size: contain;
  font-size: 28px;
  line-height: 1.5;
  padding: 70px 0 35px;
}
#PageAbout.pageIndex #ContBox01 .leadTitle .subTitle{
  margin-bottom: 15px;
  line-height: 1.8;
}
#PageAbout.pageIndex #ContBox01 #ContSubBox01{
  padding: 0 15px 40px;
}
#PageAbout.pageIndex #ContBox02 .contImg{
  width: 100%;
}
#PageAbout.pageIndex #ContBox02{
  background-color: #f7eef0;
}
#PageAbout.pageIndex #ContBox02 .leadTitle{
  font-size: 22px;
  padding: 35px 0 15px;
}
#PageAbout.pageIndex #ContBox02 #ContSubBox02{
  padding: 0 15px 35px;
}

#PageAbout.pageIndex #ContBox02 #ContSubBox02 p{
  margin-bottom: 20px;
}
#PageAbout.pageIndex  .contBox .contImg{
  width: 100%
}
#PageAbout.pageIndex #ContBox03{
  padding-bottom: 25px
}
#PageAbout.pageIndex #ContBox03 #ContSubBox03 .segWrap{
  text-align: center;
  margin-top: -22px;
}
#PageAbout.pageIndex .contBox .leadTitle{
  font-size: 22px;
  padding: 22px 0 15px;
}
#PageAbout.pageIndex .contBox .contSubBox li{
  padding-bottom: 65px;
}
#PageAbout.pageIndex .contBox .contSubBox li p{
  padding: 0 15px 25px;
}
#PageAbout.pageIndex .contBox li .leadTitle{
  font-size: 20px;
}

/*
  商品一覧開発ページ
  -------------------------------------*/
/*メインイメージ*/
#PageAbout.pageDevelopment #MainImg #MainImgInner{
  height: 215px;
  width: 100%;
  background: url(../img/contents/lower_title_01_01.jpg) no-repeat center center;
  background-size: cover;
}
/*コンテンツ*/
#PageAbout.pageDevelopment .contSubBox{
  padding: 0 15px 45px;
}
#PageAbout.pageDevelopment .contSubBox .contImg{
  width: 100%;
}
#PageAbout.pageDevelopment .contSubBox p{
  margin-bottom: 15px;
}
#PageAbout.pageDevelopment .contSubBox p:last-of-type{
  margin-bottom: 0;
}

#PageAbout.pageDevelopment #ContBox01 .leadTitle{
  font-size: 28px;
  line-height: 1.5;
  padding: 40px 0 35px;
}
#PageAbout.pageDevelopment #ContBox01 .leadTitle .subTitle{
  margin-bottom: 15px;
  line-height: 1.8;
}
#PageAbout.pageDevelopment #ContBox02{
  background-color: #f0f0f0;
  padding-top: 60px;
  position: relative;
}
#PageAbout.pageDevelopment #ContBox02::before{
  content: "";
  display: block;
  width: 1px;
  height: 50px;
  background: url(../img/00_bar_red.png) no-repeat center center;
  background-size: 1px auto;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}
#PageAbout.pageDevelopment #ContBox02 .leadTitle{
  font-size: 20px;
  line-height: 1.7;
  padding: 30px 0 15px;
}
#PageAbout.pageDevelopment #ContBox02 #ContSubBox03{
  padding-bottom: 65px;
}

/*
  品質管理ページ
  -------------------------------------*/
/*メインイメージ*/
#PageAbout.pageQuality #MainImg #MainImgInner{
  height: 215px;
  width: 100%;
  background: url(../img/contents/lower_title_01_02.jpg) no-repeat center center;
  background-size: cover;
}
/*コンテンツ*/
#PageAbout.pageQuality .contSubBox{
  padding: 0 15px 45px;
}
#PageAbout.pageQuality .contSubBox .contImg{
  width: 100%;
}
#PageAbout.pageQuality .contSubBox p{
  margin-bottom: 15px;
}
#PageAbout.pageQuality .contSubBox p:last-of-type{
  margin-bottom: 0;
}

#PageAbout.pageQuality #ContBox01 .leadTitle{
  font-size: 28px;
  line-height: 1.5;
  padding: 40px 0 35px;
}
#PageAbout.pageQuality #ContBox01 .leadTitle .subTitle{
  margin-bottom: 15px;
  line-height: 1.8;
}
#PageAbout.pageQuality #ContBox02{
  background-color: #f0f0f0;
  padding-top: 60px;
  position: relative;
}
#PageAbout.pageQuality #ContBox02::before{
  content: "";
  display: block;
  width: 1px;
  height: 50px;
  background: url(../img/00_bar_red.png) no-repeat center center;
  background-size: 1px auto;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}
#PageAbout.pageQuality #ContBox02 .leadTitle{
  font-size: 20px;
    line-height: 1.7;
    padding: 30px 0 15px;
}

/*
  化粧品OEMページ
  -------------------------------------*/
/*メインイメージ*/
#PageAbout.pageManufacturing #MainImg #MainImgInner{
  height: 215px;
  width: 100%;
  background: url(../img/contents/lower_title_01_03.jpg) no-repeat center center;
  background-size: cover;
}
/*コンテンツ*/
#PageAbout.pageManufacturing .contSubBox{
  padding: 0 15px 45px;
}
#PageAbout.pageManufacturing .contSubBox .contImg{
  width: 100%;
}
#PageAbout.pageManufacturing .contSubBox p{
  margin-bottom: 15px;
}
#PageAbout.pageManufacturing .contSubBox p:last-of-type{
  margin-bottom: 0;
}

#PageAbout.pageManufacturing #ContBox01 .leadTitle{
  font-size: 28px;
  line-height: 1.5;
  padding: 40px 0 35px;
}
#PageAbout.pageManufacturing #ContBox01 .leadTitle .subTitle{
  margin-bottom: 15px;
  line-height: 1.8;
}
#PageAbout.pageManufacturing #ContBox02{
  background-color: #f0f0f0;
  padding-top: 60px;
  position: relative;
}
#PageAbout.pageManufacturing #ContBox02::before{
  content: "";
  display: block;
  width: 1px;
  height: 50px;
  background: url(../img/00_bar_red.png) no-repeat center center;
  background-size: 1px auto;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}
#PageAbout.pageManufacturing #ContBox02 .leadTitle{
  font-size: 20px;
    line-height: 1.7;
    padding: 30px 0 15px;
}
#PageAbout.pageManufacturing #ContBox02 #ContSubBox03 .oemFlow{
  margin: 25px 0;
}
#PageAbout.pageManufacturing #ContBox02 #ContSubBox03 .oemFlow li{
  background-color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding: 18px 0;
  margin-bottom: 10px;
  position: relative;
}
#PageAbout.pageManufacturing #ContBox02 #ContSubBox03 .oemFlow li::before{
  display: block;
  position: absolute;
  top: 2px;
  left: 10px;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  color: #aaaaaa;
}
#PageAbout.pageManufacturing #ContBox02 #ContSubBox03 .oemFlow li:nth-of-type(1)::before{
  content: "01";
}
#PageAbout.pageManufacturing #ContBox02 #ContSubBox03 .oemFlow li:nth-of-type(2)::before{
  content: "02";
}
#PageAbout.pageManufacturing #ContBox02 #ContSubBox03 .oemFlow li:nth-of-type(3)::before{
  content: "03";
}
#PageAbout.pageManufacturing #ContBox02 #ContSubBox03 .oemFlow li:nth-of-type(4)::before{
  content: "04";
}
#PageAbout.pageManufacturing #ContBox02 #ContSubBox03 .oemFlow li:nth-of-type(5)::before{
  content: "05";
}
#PageAbout.pageManufacturing #ContBox02 #ContSubBox03 .oemFlow li:last-of-type{
  margin-bottom: 0;
}
#PageAbout.pageManufacturing #ContBox02 #ContSubBox03 .oemFlow li::after{
  content: "";
  display: block;
  width: 13px;
  height: 12px;
  background: url(../img/01_03_delta_grey.png) no-repeat center center;
  background-size: 13px auto;
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
}
#PageAbout.pageManufacturing #ContBox02 #ContSubBox03 .oemFlow li:last-of-type::after{
  background: none;
}
#PageAbout.pageManufacturing #ContBox02 #ContSubBox03{
  padding-bottom: 65px;
}


/*
  商品一覧ブログ　トップページ
  -------------------------------------*/
/*メインイメージ*/
#PageProducts.pageIndex #MainImg #MainImgInner{
  height: 215px;
  width: 100%;
  background: url(../img/contents/lower_title_02_00_00.jpg) no-repeat center center;
  background-size: cover;
}
/*コンテンツ*/
#PageProducts.pageIndex #ContBox01{
  background-color: #f0f0f0;
  padding: 45px 15px 60px;
}
#PageProducts.pageIndex #ContBox01 .category .category_item{
  margin-bottom: 30px;
}
#PageProducts.pageIndex #ContBox01 .category .category_item:last-of-type{
  margin-bottom: 0;
}
#PageProducts.pageIndex #ContBox01 .category .category_item dt img{
  width: 100%;
}
#PageProducts.pageIndex #ContBox01 .category .category_item dd{
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding: 16px 0;
  background: url(../img/00_arrow_red.png) no-repeat right 15px center;
  background-size: 40px auto;
  background-color: #fff;
}


/*
  商品一覧ブログ　カテゴリ一覧ページ
  -------------------------------------*/
/*コンテンツ*/
#PageProducts.pageCategory #ContBox01{
  padding-top: 100px;
}
#PageProducts.pageCategory #ContBox01 .hTitle{
  margin-bottom: 35px;
}
#PageProducts.pageCategory #ContBox01 img{
  width: 100%;
}

#PageProducts.pageCategory #ContBox02{
  background-color: #f0f0f0;
}


/*
  商品一覧ブログ　詳細ページ
  -------------------------------------*/
/*コンテンツ*/
#PageProducts.pageEntry #ContBox01{
  padding-top: 60px;
}
#PageProducts.pageEntry #ContBox01 .hTitle{
  margin-bottom: 35px;
}
#PageProducts.pageEntry #ContBox01 .thumb{
  width: 100%;
}
#PageProducts.pageEntry #ContBox01 #ContSubBox01{
  padding: 15px;
}
#PageProducts.pageEntry #ContBox01 .infoBox .label{
  display: block;
}
#PageProducts.pageEntry #ContBox01 .infoBox .label b{
  background-color: #222222;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  padding: 5px 10px;
}
#PageProducts.pageEntry #ContBox01 .infoBox .recommend{
  font-size: 16px;
  display: block;
  margin-top: 10px;
}
#PageProducts.pageEntry #ContBox01 .infoBox .title{
  font-size: 20px;
  font-weight: 700;
  display: block;
  margin-top: 5px;
}
#PageProducts.pageEntry #ContBox01 .infoBox .type{
  font-size: 12px;
  font-weight: 700;
  color: #a4051a;
  display: inline-block;
  margin-top: 5px;
  letter-spacing: 0.06em
}
#PageProducts.pageEntry #ContBox01 .infoBox .typeBox{
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 5px;
}
#PageProducts.pageEntry #ContBox01 .infoBox .typeBox dd{
  vertical-align: middle;
  display: inline-block;
  margin-right: -3px;
}
#PageProducts.pageEntry #ContBox01 .infoBox .typeBox dd.morningIcon{
  background:url(../img/02_01_ico_morning.png) no-repeat center center;
  background-size: 15px auto;
  width: 15px;
  height: 15px;
}
#PageProducts.pageEntry #ContBox01 .infoBox .typeBox dd.daytimeIcon{
  background:url(../img/02_01_ico_daytime.png) no-repeat center center;
  background-size: 16px auto;
  width: 16px;
  height: 15px;
}
#PageProducts.pageEntry #ContBox01 .infoBox .typeBox dd.nightIcon{
  background:url(../img/02_01_ico_night.png) no-repeat center center;
  background-size: 13px auto;
  width: 13px;
  height: 15px;
}
#PageProducts.pageEntry #ContBox01 .infoBox .productCode{
  font-size: 12px;
  margin-top: 5px;
  margin-right: 5px;
  display: inline-block;
}
#PageProducts.pageEntry #ContBox01 .infoBox .productCode dt{
  display: inline-block;
}
#PageProducts.pageEntry #ContBox01 .infoBox .productCode dd{
  display: inline-block;
}
#PageProducts.pageEntry #ContBox01 .infoBox .capacity{
  font-size: 12px;
  margin-top: 5px;
  display: inline-block;
}
#PageProducts.pageEntry #ContBox01 .infoBox .capacity dt{
  display: inline-block;
}
#PageProducts.pageEntry #ContBox01 .infoBox .capacity dd{
  display: inline-block;
}
#PageProducts.pageEntry #ContBox01 .infoBox .infoBox_cont{
}
#PageProducts.pageEntry #ContBox01 .infoBox .leadTitle{
  text-align: left;
  line-height: 1.6;
  margin-top: 16px;
}
#PageProducts.pageEntry #ContBox01 .infoBox .paraBox{
  margin-top: 12px;
}
#PageProducts.pageEntry #ContBox01 .infoBox .paraBox.entryBox p{
  margin-bottom: 0!important;
}
#PageProducts.pageEntry #ContBox01 .infoBox .paraBox.entryBox img{
  width: auto!important;
  height: auto!important;
  margin: 5px 0!important;
}
#PageProducts.pageEntry #ContBox01 .infoBox_price{
  border-top: 1px solid #e1e1e1;
  margin-top: 20px;
  padding-top: 15px;
}
#PageProducts.pageEntry .infoBox_price::after{
  content: "";
  display: block;
  clear: both;
}
/*価格2箇所共通*/
#PageProducts.pageEntry .infoBox_price .price{
  font-size: 26px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  /*margin-right: 20px;*/
  letter-spacing: 0.0em;
  margin-bottom: 4px;
}
_::-webkit-full-page-media, _:future, :root #PageProducts.pageEntry .infoBox_price .price {
    letter-spacing: -.04em;
}

#PageProducts.pageEntry .infoBox_price .price span{
  font-size: 12px;
}
#PageProducts.pageEntry .infoBox_price .price .tax{
  font-size: 12px;
  letter-spacing: 0.04em;
  font-weight: normal;
}
#PageProducts.pageEntry .infoBox_price .convLinkBtn{
  color: #fff;
  border-radius: 5px;
  padding: 13px 31px 13px 12px;
  display: inline-block;
  background: url(../img/00_ico_blank.png) no-repeat right 10px center;
  background-size: 14px auto;
  background-color: #a4051a;
  font-size: 16px;
  float: right;
}

/*価格2箇所共通 ディスカウントスタイル*/
#PageProducts.pageEntry .pr_labelBox {
  display: flex;
  flex-wrap: wrap;
  margin: -5px 0 10px -5px;
}
#PageProducts.pageEntry .pr_labelBox .pr_label {
  background-color: #f46082;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.2em;
  line-height: 24px;
  padding: 1px 0.6333em 0 0.79166em;
  margin: 5px 0 0 5px;
}
#PageProducts.pageEntry .infoBox_price .original_price{
  color: #888888;
  text-decoration: line-through;
  font-size: 12px;
  letter-spacing: 0.06em;
}
#PageProducts.pageEntry .infoBox_price .original_price_taxin{

}
#PageProducts.pageEntry .infoBox_price .price.discountStyle{
  color: #f36e8d;
  padding-left: 35px;
  position: relative;
  margin-top: -8px;
  display: block;
}
#PageProducts.pageEntry .infoBox_price .price.discountStyle::before{
  content: "";
  display: block;
  width: 12px;
  height: 13px;
  background: url(../img/02_01_delta_grey_right.png) no-repeat center center;
  background-size: 12px auto;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}



#PageProducts.pageEntry #ContBox02{
  background-color: #f7eef0;
  margin-top: 15px;
}
#PageProducts.pageEntry #ContBox02 #ContSubBox02{
  padding: 30px 15px 20px;
}
#PageProducts.pageEntry #ContBox02 dl{
  background-color: #fff;
  margin-bottom: 10px;
}
#PageProducts.pageEntry #ContBox02 dt{
  padding: 18px 15px;
  font-size: 16px;
  font-weight: 700;
  background: url(../img/00_plus_red.png) no-repeat right 15px center;
  background-size: 20px auto;
}
#PageProducts.pageEntry #ContBox02 dt.active{
  background: url(../img/00_minus_red.png) no-repeat right 15px center;
  background-size: 20px auto;
}
#PageProducts.pageEntry #ContBox02 dt.show{
  background: url(../img/00_minus_red.png) no-repeat right 15px center;
  background-size: 20px auto;
}
#PageProducts.pageEntry #ContBox02 dt.show.active{
  background: url(../img/00_plus_red.png) no-repeat right 15px center;
  background-size: 20px auto;
}
#PageProducts.pageEntry #ContBox02 dd{
  border-top: 1px solid #f7eef0;
  font-size: 16px;
  line-height: 1.8;
  padding: 24px 15px;
}
#PageProducts.pageEntry #ContBox02 dt.show + .showBox{
  display: block;
}
#PageProducts.pageEntry #ContBox02 dl.wyEdit .entryBox p{
  margin-bottom: 0!important;
}
#PageProducts.pageEntry #ContBox02 dl.wyEdit .entryBox img{
  width: auto!important;
  height: auto!important;
  margin: 20px 0!important;
}
#PageProducts.pageEntry #ContBox03 #ContSubBox03{
  padding: 15px 15px 45px;
}
#PageProducts.pageEntry #ContBox04{
  background-color: #f0f0f0;
}
#PageProducts.pageEntry #ContBox04 .hTitle{
  margin: 0 0 20px;
}
#PageProducts.pageEntry #ContBox04 .relatedPosts{
  padding: 35px 15px 30px;
}





/*
  ブログページ　カテゴリ共通
  -------------------------------------*/
  #PageBlog .post .post_item a .time{
    font-family: 'Lato', sans-serif;
    font-size: 14px;
  }
  #PageBlog .post .post_item a .title{
    font-size: 16px;
  }


/*
  ブログページ　お知らせカテゴリ
  -------------------------------------*/
/*メインイメージ*/
#PageBlog.pageCategoryNews #MainImg #MainImgInner{
  width: 100%;
  background: url(../img/contents/lower_title_05_01.jpg) no-repeat center center;
  background-size: cover;
}
#PageBlog.pageCategoryNews #ContBox01{
  padding: 45px 15px 80px;
}

#PageBlog.pageCategoryNews #ContBox01 #ContSubBox01 .post_wrap{
  margin-bottom: 30px;
}
#PageBlog.pageCategoryNews #ContBox01 #ContSubBox01 .post .post_item{
  padding: 25px 0;
  border-bottom: 1px solid #e1e1e1;
}
#PageBlog.pageCategoryNews #ContBox01 #ContSubBox01 .post .post_item:first-of-type{
  border-top: 1px solid #e1e1e1;
}
#PageBlog.pageCategoryNews #ContBox01 #ContSubBox01 .post .post_item a{
  display: flex;
  background: url(../img/00_arrow02_red.png) no-repeat right 4px center;
  background-size: 10px auto;
}
#PageBlog.pageCategoryNews #ContBox01 #ContSubBox01 .post .post_item a .time{
  color: #888888;
}
#PageBlog.pageCategoryNews #ContBox01 #ContSubBox01 .post .post_item a .title{
  padding-right: 20px;
  line-height: 1.8;
}

#PageBlog.pageCategoryNews #ContBox01 #ContSubBox01 .post .post_item a figure {
  margin-right: 15px;
  display: none;
}
#PageBlog.pageCategoryNews #ContBox01 #ContSubBox01 .post .post_item a .thumb{
  width: 125px;
}


/*
  ブログページ　美容・コラムカテゴリ
  -------------------------------------*/
/*メインイメージ*/
#PageBlog.pageCategoryBeauty #MainImg #MainImgInner{
  width: 100%;
  background: url(../img/contents/lower_title_05_02.jpg) no-repeat center center;
  background-size: cover;
}
#PageBlog.pageCategoryBeauty #ContBox01{
  padding: 45px 15px 80px;
}

#PageBlog.pageCategoryBeauty #ContBox01 #ContSubBox01 .post_wrap{
  margin-bottom: 30px;
}
#PageBlog.pageCategoryBeauty #ContBox01 #ContSubBox01 .post .post_item{
  border-bottom: 1px solid #e1e1e1;
}
#PageBlog.pageCategoryBeauty #ContBox01 #ContSubBox01 .post .post_item:first-of-type{
  border-top: 1px solid #e1e1e1;
}
#PageBlog.pageCategoryBeauty #ContBox01 #ContSubBox01 .post .post_item a{
  display: flex;
  background: url(../img/00_arrow_red.png) no-repeat right bottom 20px;
  background-size: 40px auto;
  padding: 27px 0 31px;
}

#PageBlog.pageCategoryBeauty #ContBox01 #ContSubBox01 .post .post_item a figure {
  margin-right: 15px
}
#PageBlog.pageCategoryBeauty #ContBox01 #ContSubBox01 .post .post_item a .thumb{
  width: 125px;
  margin-top: 5px;
}
#PageBlog.pageCategoryBeauty #ContBox01 #ContSubBox01 .post .post_item a .time{
  color: #888888;
}
#PageBlog.pageCategoryBeauty #ContBox01 #ContSubBox01 .post .post_item a .title{
  line-height: 1.8;
}

/*
  ブログページ　お客様の声カテゴリ
  -------------------------------------*/
/*メインイメージ*/
#PageBlog.pageCategoryVoice #MainImg #MainImgInner{
  width: 100%;
  background: url(../img/contents/lower_title_05_03.jpg) no-repeat center center;
  background-size: cover;
}
#PageBlog.pageCategoryVoice #ContBox01{
  padding: 45px 15px 80px;
}

#PageBlog.pageCategoryVoice #ContBox01 #ContSubBox01 .post_wrap{
  margin-bottom: 30px;
}
#PageBlog.pageCategoryVoice #ContBox01 #ContSubBox01 .post .post_item{
  border-bottom: 1px solid #e1e1e1;
}
#PageBlog.pageCategoryVoice #ContBox01 #ContSubBox01 .post .post_item:first-of-type{
  border-top: 1px solid #e1e1e1;
}
#PageBlog.pageCategoryVoice #ContBox01 #ContSubBox01 .post .post_item a{
  display: flex;
  background: url(../img/00_arrow_red.png) no-repeat right bottom 20px;
  background-size: 40px auto;
  padding: 27px 0 31px;
}

#PageBlog.pageCategoryVoice #ContBox01 #ContSubBox01 .post .post_item a figure {
  margin-right: 15px
}
#PageBlog.pageCategoryVoice #ContBox01 #ContSubBox01 .post .post_item a .thumb{
  width: 125px;
  margin-top: 5px;
}
#PageBlog.pageCategoryVoice #ContBox01 #ContSubBox01 .post .post_item a .time{
  color: #888888;
}
#PageBlog.pageCategoryVoice #ContBox01 #ContSubBox01 .post .post_item a .title{
  line-height: 1.8;
}



/*
  ブログページ　よくある質問カテゴリ
  -------------------------------------*/
/*メインイメージ*/
#PageBlog.pageCategoryFaq #MainImg #MainImgInner{
  width: 100%;
  background: url(../img/contents/lower_title_05_04.jpg) no-repeat center center;
  background-size: cover;
}
/*コンテンツ*/
#PageBlog.pageCategoryFaq .contBox{
  padding: 40px 15px 45px;
  border-bottom: 1px solid #e1e1e1;
}
#PageBlog.pageCategoryFaq .contBox:last-of-type{
  padding-bottom: 80px;
  border-bottom: none;
}
#PageBlog.pageCategoryFaq .contBox h3{
  font-size: 20px;
  margin-bottom: 20px;
}
#PageBlog.pageCategoryFaq .contBox .contSubBox .post_wrap{
  margin-bottom: 30px;
}
#PageBlog.pageCategoryFaq .contBox .contSubBox .post .post_item{
  border-bottom: 1px solid #e1e1e1;
}
#PageBlog.pageCategoryFaq .contBox .contSubBox .post .post_item:first-of-type{
  border-top: 1px solid #e1e1e1;
}
#PageBlog.pageCategoryFaq .contBox .contSubBox .post .post_item a{
  display: block;
  background: url(../img/00_ico_subfaq.png) no-repeat left top 18px;
  background-size: 35px auto;
  padding: 24px 0 23px 45px;
}
#PageBlog.pageCategoryFaq .contBox .contSubBox .post .post_item a .time{
  color: #888888;
}

#PageBlog.pageCategoryFaq .contBox .contSubBox .post .post_item a figure {
  margin-right: 15px;
  display: none;
}
#PageBlog.pageCategoryFaq .contBox .contSubBox .post .post_item a .thumb{
  width: 125px;
}


/*
  ブログページ　よくある質問サブカテゴリ
  -------------------------------------*/
/*メインイメージ*/
#PageBlog.pageSubCategoryFaq #MainImg #MainImgInner{
  width: 100%;
  background: url(../img/contents/lower_title_05_04.jpg) no-repeat center center;
  background-size: cover;
}
/*コンテンツ*/
#PageBlog.pageSubCategoryFaq #ContBox01{
  padding: 40px 15px 80px;
}
#PageBlog.pageSubCategoryFaq #ContBox01 h3{
  font-size: 20px;
  margin-bottom: 20px;
}
#PageBlog.pageSubCategoryFaq #ContBox01 #ContSubBox01 .post_wrap{
  margin-bottom: 30px;
}
#PageBlog.pageSubCategoryFaq #ContBox01 #ContSubBox01 .post .post_item{
  border-bottom: 1px solid #e1e1e1;
}
#PageBlog.pageSubCategoryFaq #ContBox01 #ContSubBox01 .post .post_item:first-of-type{
  border-top: 1px solid #e1e1e1;
}
#PageBlog.pageSubCategoryFaq #ContBox01 #ContSubBox01 .post .post_item a{
  display: block;
  background: url(../img/00_ico_subfaq.png) no-repeat left top 18px;
  background-size: 35px auto;
  padding: 24px 0 23px 45px;
}
#PageBlog.pageSubCategoryFaq #ContBox01 #ContSubBox01 .post .post_item a .time{
  color: #888888;
  display: none;
}

#PageBlog.pageSubCategoryFaq #ContBox01 #ContSubBox01 .post .post_item a figure {
  margin-right: 15px;
  display: none;
}
#PageBlog.pageSubCategoryFaq #ContBox01 #ContSubBox01 .post .post_item a .thumb{
  width: 125px;
}


/*
  ブログページ　記事詳細
  -------------------------------------*/
/*メインイメージ*/
#PageBlog.pageEntry #MainImg #MainImgInner{
  height: 140px;
  width: 100%;
}
/*コンテンツ*/
#PageBlog.pageEntry #ContBox01 #ContSubBox01{
  padding: 0 15px 45px;
}
#PageBlog.pageEntry #ContBox01 #ContSubBox01 .imgBox{
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
#PageBlog.pageEntry #ContBox01 #ContSubBox01 .imgBox img{
  /*width: 100%;*/
  max-width: 100%;
  height: auto;
}
#PageBlog.pageEntry #ContBox01 #ContSubBox01 .infoBox_header{
  border-bottom: 1px solid #e1e1e1;
  padding: 20px 0;
}
#PageBlog.pageEntry #ContBox01 #ContSubBox01 .infoBox_header .time{
  font-size: 14px;
  color: #8f8f8f;
  font-family: "Lato", sans-serif;
  margin-bottom: 10px;
}
#PageBlog.pageEntry #ContBox01 #ContSubBox01 .infoBox_header .title{
  font-size: 18px;
  font-weight: 700;
  line-height: 1.8;
}
#PageBlog.pageEntry #ContBox01 #ContSubBox01 .infoBox_cont{
  padding: 40px 0;
  border-bottom: 1px solid #e1e1e1;
}
#PageBlog.pageEntry #ContBox01 #ContSubBox01 .infoBox_cont h3{
  font-size: 16px;
  font-weight: 700;
  color: #a4051a;
  margin-bottom: 5px;
}
#PageBlog.pageEntry #ContBox01 #ContSubBox01 .infoBox_cont p{
  font-size: 16px;
  margin-bottom: 20px;
}
#PageBlog.pageEntry #ContBox01 #ContSubBox01 .infoBox_cont p:last-of-type{
  font-size: 16px;
  margin-bottom: 15px;
}
#PageBlog.pageEntry #ContBox01 #ContSubBox01 .btnWrap{
  text-align: center;
  padding: 28px 0;
}
#PageBlog.pageEntry #ContBox01 #ContSubBox01 .categoryLinkBtn{
  background-color: #222222;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  padding: 10px 15px;
  display: inline-block;
}




/*
  キャンペーン情報ブログ　共通
  -------------------------------------*/
#PageCampaign .labelBox {
  display: flex;
  flex-wrap: wrap;
  margin: -5px 0 0 -5px;
}

#PageCampaign .labelBox .label {
  background-color: #a4051a;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.2em;
  line-height: 24px;
  padding: 1px 0.6333em 0 0.79166em;
  margin: 5px 0 0 5px;
}

#PageCampaign .innerBasic{
  padding: 0 15px;
}
#PageCampaign .menu_sp .contactList.innerBasic{
  padding: 40px 15px;
}

/*
  キャンペーン情報ブログ　トップ
  -------------------------------------*/
#PageCampaign.pageIndex #Container {
  padding-top: 60px;
}

#PageCampaign.pageIndex #Main {
  background-color: #f7eef0;
  border-top: 1px solid #f36e8d;
}

#PageCampaign.pageIndex #Main #ContBox01 {
  padding-top: 38px;
  padding-bottom: 55px;
}

#PageCampaign.pageIndex #Main #ContBox01 .hTitle .enTitle {
  color: #f36e8d;
}

#PageCampaign.pageIndex #Main #ContBox01 .post {
  margin-top: 34px;
}

#PageCampaign.pageIndex #Main #ContBox01 .post_item {
  background-color: #fff;
}
#PageCampaign.pageIndex #Main #ContBox01 .post_item a{
  padding: 30px;
  display: block;
}
#PageCampaign.pageIndex #Main #ContBox01 .post_item a:hover{
  text-decoration: none;
}

#PageCampaign.pageIndex #Main #ContBox01 .post_item .labelBox {
  justify-content: left;
  margin-bottom: 11px;
}

#PageCampaign.pageIndex #Main #ContBox01 .post_item .title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.05em;
  line-height: 27px;
  margin-bottom: 7px;
}

#PageCampaign.pageIndex #Main #ContBox01 .post_item time {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: #a4051a;
  letter-spacing: 0.05em;
  margin-bottom: 12px;
}

#PageCampaign.pageIndex #Main #ContBox01 .post_item .thumb {
  margin-bottom: 30px;
}

#PageCampaign.pageIndex #Main #ContBox01 .post_item .thumb img {
  width: 100%;
}

#PageCampaign.pageIndex #Main #ContBox01 .post_item .moreLinkBtn {
  display: inline-block;
  width: 195px;
  height: 40px;
  background: #f46082 url(../img/00_arrow_white.png) no-repeat right 15px top 17px;
  background-size: auto 4px;
  font-size: 14px;
  letter-spacing: 0.2em;
  line-height: 40px;
  text-decoration: none;
  padding: 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#PageCampaign.pageIndex #Main #ContBox01 .post_item + .post_item {
  margin-top: 30px;
}


/*
  キャンペーン情報ブログ　詳細
  -------------------------------------*/
#PageCampaign.pageEntry #Container {
  padding-top: 60px;
}

#PageCampaign.pageEntry #Main #ContBox01 .hTitle {
  background-color: #f7eef0;
  border-top: 1px solid #f36e8d;
  padding: 38px 0 30px;
}

#PageCampaign.pageEntry #Main #ContBox01 .hTitle .enTitle {
  color: #f36e8d;
}

#PageCampaign.pageEntry #Main #ContBox01 .header {
  text-align: center;
  padding: 42px 0 30px;
}

#PageCampaign.pageEntry #Main #ContBox01 .header .title {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.05em;
  line-height: 27px;
  margin-bottom: 4px;
}

#PageCampaign.pageEntry #Main #ContBox01 .header time {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: #a4051a;
  letter-spacing: 0.05em;
  margin-bottom: 12px;
}

#PageCampaign.pageEntry #Main #ContBox01 .header .labelBox {
  justify-content: center;
}

#PageCampaign.pageEntry #Main #ContBox01 .imgBox .gallery_top .slider_wrapper {
  margin-bottom: 0 !important;
  /**/
  background-color: #f0f0f0;
}

#PageCampaign.pageEntry #Main #ContBox01 .imgBox .gallery_top .slide {
  padding-top: 70.4vw;
  background-size: contain;
  background-repeat: no-repeat;
}
/**/
/*#PageCampaign.pageEntry #Main #ContBox01 .imgBox .gallery_top .slick-slide img{
  width: 100%;
  object-fit: contain;
  max-height: 60vh;
}*/
#PageCampaign.pageEntry #Main #ContBox01 .imgBox .gallery_thumbs .slide {
  padding-top: 12vw;
  background-size: cover;
  background-repeat: no-repeat;
  width: 97%;
}
/**/

#PageCampaign.pageEntry #Main #ContBox01 .imgBox .gallery_thumbs {
  width: 100%;
  /*height: 65px;*/
  background-color: #fda8ba;
  padding: 12px 30px 11px;
}

#PageCampaign.pageEntry #Main #ContBox01 .imgBox .gallery_thumbs .slide {
  /*width: 59px;*/
  //max-width: 89px;
  /*height: 42px;*/
}
/**/
#PageCampaign.pageEntry #Main #ContBox01 .gallery_thumbs .slick-slide img{
  width: 100%;
}
/**/

/*#PageCampaign.pageEntry #Main #ContBox01 .imgBox .gallery_thumbs .slide + .slide {
  margin-left: 5px;
}*/

#PageCampaign.pageEntry #Main #ContBox01 .imgBox .gallery_thumbs .slick-arrow {
  width: 5px;
  height: 8px;
  background: url(../img/00_arrow02_white.png) no-repeat left top;
  background-size: 100% auto;
}

#PageCampaign.pageEntry #Main #ContBox01 .imgBox .gallery_thumbs .slick-arrow:before {
  display: none;
}

#PageCampaign.pageEntry #Main #ContBox01 .imgBox .gallery_thumbs .slick-arrow.slick-prev {
  transform: scale(-1, 1);
  left: -15px;
  top: 45%;
}

#PageCampaign.pageEntry #Main #ContBox01 .imgBox .gallery_thumbs .slick-arrow.slick-next {
  right: -15px;
}

#PageCampaign.pageEntry #Main #ContBox01 .imgBox .gallery_thumbs .slick-arrow:focus, #PageCampaign.pageEntry #Main #ContBox01 .imgBox .gallery_thumbs .slick-arrow:hover {
  background: url(../img/00_arrow02_white.png) no-repeat left top;
  background-size: 100% auto;
}

#PageCampaign.pageEntry #Main #ContBox01 .note {
  background-color: #f0f0f0;
  padding: 0.53125em 15px;
}

#PageCampaign.pageEntry #Main #ContBox01 .note p {
  margin: 0 auto;
  font-size: 14px;
  color: #888888;
  line-height: 24px;
}

#PageCampaign.pageEntry #Main #ContBox01 .infoBox {
  /*padding: 24px 0 38px;*/
  padding: 14px 0 22px;
}

#PageCampaign.pageEntry #Main #ContBox01 .infoBox_cont {
  padding: 0 15px;
}

#PageCampaign.pageEntry #Main #ContBox01 .infoBox_cont h4 {
  font-weight: bold;
  color: #a4051a;
  line-height: 29px;
}

#PageCampaign.pageEntry #Main #ContBox01 .infoBox_cont h4:nth-of-type(2) {
  margin-top: 15px;
}

#PageCampaign.pageEntry #Main #ContBox01 .infoBox_cont p {
  line-height: 29px;
}

#PageCampaign.pageEntry #Main #ContBox01 .entryBox .infoBox_cont  h3:first-of-type {
  margin: 0px 0 15px!important;
}
#PageCampaign.pageEntry #Main #ContBox01 .entryBox img{
  width: auto!important;
  height: auto!important;
  margin: 5px 0!important;
}

/*購入ボタン*/
#PageCampaign.pageEntry #Main #ContBox01 .entryBox .convLinkBtn{
  display: inline-block;
  border-radius: 5px;
  padding: 13px 31px 13px 12px;
  background: url(../img/00_ico_blank.png) no-repeat right 10px center;
  background-color: #a4051a;
  background-size: 14px auto;
  font-size: 16px;
  color: #fff!important;
  text-decoration: none!important;
}


#PageCampaign.pageEntry #Main #ContBox02{
  padding-top: 34px;
  padding-bottom: 35px;
}
#PageCampaign.pageEntry #Main #ContBox02 .hTitle{
  margin-bottom: 20px;
}
#PageCampaign.pageEntry #Main #ContBox02 .enTitle{
  color: #f36e8d;
}
#PageCampaign.pageEntry #Main #ContBox02{
  background-color: #f7eef0;
}
#PageCampaign.pageEntry #Main #ContBox02 .relatedPosts{
  background-color: #f7eef0;
}




/*
  お問い合わせ　共通
  -------------------------------------*/
#PageContact #MainImg #MainImgInner {
  background: url(../img/contents/lower_title_07.jpg) no-repeat center;
  background-size: 100% auto;
}

#PageContact #Main {
  min-height: unset;
}

#PageContact .innerBasic{
  padding: 0 15px;
}

#PageContact .menu_sp .contactList.innerBasic{
  padding: 40px 15px;
}

#PageContact #Footer .innerBasic{
  padding: 0;
}


/*
  お問い合わせ　入力ページ
  -------------------------------------*/
#PageContact.pageIndex #Main #ContBox01 {
  padding-top: 33px;
  padding-bottom: 10px;
}

#PageContact.pageIndex #Main #ContBox01 .explainBox {
  margin-bottom: 22px;
}

#PageContact.pageIndex #Main #ContBox01 .explainBox p {
  line-height: 29px;
}

#PageContact.pageIndex #Main #ContBox01 .contactList li {
  border-top: 1px solid #e1e1e1;
  padding: 23px 0 30px;
}

#PageContact.pageIndex #Main #ContBox01 .contactList li dl {
  text-align: center;
}

#PageContact.pageIndex #Main #ContBox01 .contactList li dt {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.075em;
  line-height: 34px;
  padding-left: 0.075em;
}

#PageContact.pageIndex #Main #ContBox01 .contactList li dd.redBox {
  background-color: #a4051a;
  color: #fff;
  border-radius: 5px;
  padding: 13px 0 15px;
  margin-top: 6px;
}

#PageContact.pageIndex #Main #ContBox01 .contactList li dd.redBox a {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: #fff;
  background: url(../img/00_ico_freedial.png) no-repeat left top 5px;
  background-size: 35px auto;
  padding-left: 43px;
  text-decoration: underline;
}

#PageContact.pageIndex #Main #ContBox01 .contactList li dd.redBox span {
  display: block;
  font-size: 14px;
  letter-spacing: 0.05em;
  margin-top: 1px;
}

#PageContact.pageIndex #Main #ContBox01 .contactList li dd + dd {
  font-size: 14px;
  margin-top: 8px;
}

#PageContact.pageIndex #Main #ContBox01 .contactList li:nth-of-type(1) {
  padding-bottom: 24px;
}

#PageContact.pageIndex #Main #ContBox01 .contactList li:nth-of-type(2) dd.redBox a {
  padding-left: 32px;
  background-image: url(../img/00_ico_tel.png);
  background-position: left top 4px;
  background-size: 23px auto;
}

#PageContact.pageIndex #Main #ContBox01 .contactList li:nth-of-type(3) dd.redBox a {
  text-decoration: none;
}

#PageContact.pageIndex #Main #ContBox02 {
  background-color: #f0f0f0;
  padding-top: 37px;
  padding-bottom: 71px;
}

#PageContact.pageIndex #Main #ContBox02 .contSubBox {
  margin-top: 19px;
  border-top: 1px solid #e1e1e1;
}

#PageContact.pageIndex #Main #ContBox02 .contSubBox form table tr.location td {
  position: relative;
  padding-left: 20px;
}

#PageContact.pageIndex #Main #ContBox02 .contSubBox form table tr.location td span {
  position: absolute;
  top: 13px;
  left: -1px;
}

#PageContact.pageIndex #Main #ContBox02 .contSubBox form table tr.location td #Zipcode {
  width: 150px;
}

#PageContact.pageIndex #Main #ContBox02 .contSubBox form table tr.location td #Address {
  margin-top: 9px;
}

/*
  お問い合わせ　確認ページ
  -------------------------------------*/
#PageContact.pageConfirm #Main #ContBox01 {
  padding-top: 33px;
  padding-bottom: 71px;
}

#PageContact.pageConfirm #Main #ContBox01 .errList {
  margin: 20px 0;
}

#PageContact.pageConfirm #Main #ContBox01 .errList p {
  margin-bottom: 0;
}

#PageContact.pageConfirm #Main #ContBox01 .confirm {
  margin-bottom: 20px;
}

#PageContact.pageConfirm #Main #ContBox01 .btnBox .return {
  text-align: center;
  margin-top: 10px;
}

#PageContact.pageConfirm #Main #ContBox01 .btnBox .return a {
  display: inline-block;
  font-size: 14px;
  text-decoration: underline;
}

/*
  お問い合わせ　完了ページ
  -------------------------------------*/
#PageContact.pageThanks #Main #ContBox01 {
  padding-top: 33px;
  padding-bottom: 71px;
}

#PageContact.pageThanks #Main #ContBox01 .homeBtn {
  display: block;
  width: 225px;
  height: 60px;
  background-color: #a4051a;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.05em;
  line-height: 60px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  margin: 30px auto 0;
  font-size: 14px;
}


/*
  プライバシーポリシー
  -------------------------------------*/
/*メインイメージ*/
#PagePolicy.pageIndex #MainImg #MainImgInner{
  height: 135px;
  width: 100%;
}
/*コンテンツ*/
#PagePolicy.pageIndex #ContBox01{
  padding: 0 15px 80px;
}
#PagePolicy.pageIndex #ContBox01 #ContSubBox01{
  padding: 25px 0 0;
  border-top: 1px solid #e1e1e1;
}
#PagePolicy.pageIndex #ContBox01 #ContSubBox01 .titleRed{
  font-size: 18px;
  font-weight: 700;
  color: #a4051a;
  margin-bottom: 15px;
  padding-top: 10px;
}
#PagePolicy.pageIndex #ContBox01 #ContSubBox01 p{
  margin-bottom: 15px;
  line-height: 1.85;
}
#PagePolicy.pageIndex #ContBox01 #ContSubBox01 ul{
  margin-bottom: 15px;
}
#PagePolicy.pageIndex #ContBox01 #ContSubBox01 ul li{
  padding-left: 15px;
  line-height: 1.85;
  font-size: 16px;
  position: relative;
}
#PagePolicy.pageIndex #ContBox01 #ContSubBox01 ul li::before{
  content:"";
  display: block;
  width: 6px;
  height: 6px;
  background-color: #a4051a;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  left: 0;
}
#PagePolicy.pageIndex #ContBox01 #ContSubBox01 dl{
  line-height: 1.85;
  letter-spacing: 0.08em;
}
#PagePolicy.pageIndex #ContBox01 #ContSubBox01 dl dt{
  font-size: 16px;
  font-weight: 700;
}
#PagePolicy.pageIndex #ContBox01 #ContSubBox01 dl dd{
  font-size: 16px;
}






/*
  企業情報
  -------------------------------------*/
#PageCompany.pageIndex #MainImg #MainImgInner {
  width: 100%;
  background: url("../img/contents/lower_title_04.jpg") no-repeat center center;
  background-size: cover;
}
#PageCompany.pageIndex #Main .contBox{
  padding-right: 15px;
  padding-left: 15px;
}
#PageCompany.pageIndex #Main #ContBox01{
  padding-top: 38px;
  padding-bottom: 14px;
}
#PageCompany.pageIndex #Main #ContBox01 .leadTitle{
  margin-bottom: 28px;
  font-size: 28px;
}
#PageCompany.pageIndex #Main #ContBox01 .leadTitle span{
  margin-bottom: 3px;
}

#PageCompany.pageIndex #Main #ContBox01 #ContSubBox01 {
  margin-bottom: 10px;
}

#PageCompany.pageIndex #Main #ContBox01 #ContSubBox01 .leadTitle{
  margin-bottom: 10px;
}

#PageCompany.pageIndex #Main #ContBox02{
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 53px;
}

#PageCompany.pageIndex #Main #ContBox02 figure{
  position: relative;
  margin-bottom: 23px;
}

#PageCompany.pageIndex #Main #ContBox02 figure img{
  width: 100%;
  height: auto;
}
#PageCompany.pageIndex #Main #ContBox02 figure figcaption{
  position: absolute;
  top: 28px;
  right: 27px;
  text-align: right;
  letter-spacing: 2px;
  font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif; 
  font-size: 20px;
  font-weight: 400;
}

#PageCompany.pageIndex #Main #ContBox02 figure figcaption span{
  display: block;
  margin-bottom: 3px;
  text-align: right;
  letter-spacing: 1px;
  font-size: 12px;
}

#PageCompany.pageIndex #Main #ContBox02 #ContSubBox02 b{
  position: relative;
  display: block;
  margin-bottom: 17px;
  padding-top: 31px;
  padding-bottom: 18px;
  line-height: 1.8;
  font-weight: bold;
  font-size: 16px;
}
#PageCompany.pageIndex #Main #ContBox02 #ContSubBox02 b::before,
#PageCompany.pageIndex #Main #ContBox02 #ContSubBox02 b::after{
  content: "";
  position: absolute;
  display: block;
  width: 25px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 25px auto;
}
#PageCompany.pageIndex #Main #ContBox02 #ContSubBox02 b::before{
  top: 0;
  left: 2px;
  background-image: url("../img/04_01_ico_quote_left.png");
}
#PageCompany.pageIndex #Main #ContBox02 #ContSubBox02 b::after{
  right: 2px;
  bottom: 0;
  background-image: url("../img/04_01_ico_quote_right.png");
}
#PageCompany.pageIndex #Main #ContBox02 #ContSubBox02 p{
  margin-bottom: 15px;
}

#PageCompany.pageIndex #Main #ContBox03{
  border-bottom: 1px solid #e1e1e1;
  padding-top: 36px;
  padding-bottom: 35px;
}
#PageCompany.pageIndex #Main #ContBox03 .hTitle{
  margin-bottom: 19px;
}
#PageCompany.pageIndex #Main #ContBox03 table{
  width: 100%;
}
#PageCompany.pageIndex #Main #ContBox03 table tr th,
#PageCompany.pageIndex #Main #ContBox03 table tr td{
  padding-top: 24px;
  padding-bottom: 24px;
  line-height: 1.8;
}
#PageCompany.pageIndex #Main #ContBox03 table tr th{
  border-top: 1px solid #a4051a;
  width: 22%;
  letter-spacing: 0;
  font-weight: bold;
  font-size: 14px;
}
#PageCompany.pageIndex #Main #ContBox03 table tr td{
  padding-left: 15px;
  border-top: 1px solid #e1e1e1;
  font-size: 16px;
}

#PageCompany.pageIndex #Main #ContBox03 table tr td a{
  color: #222;
}
#PageCompany.pageIndex #Main #ContBox04{
  border-bottom: 1px solid #e1e1e1;
  padding-top: 34px;
  padding-bottom: 54px;
}
#PageCompany.pageIndex #Main #ContBox04 .hTitle{
  margin-bottom: 15px;
}
#PageCompany.pageIndex #Main #ContBox04 #ContSubBox04 dl dt,
#PageCompany.pageIndex #Main #ContBox04 #ContSubBox04 dl dd{
  line-height: 1.8;
}
#PageCompany.pageIndex #Main #ContBox04 #ContSubBox04 dl dt{
  position: relative;
  margin-bottom: 11px;
}
#PageCompany.pageIndex #Main #ContBox04 #ContSubBox04 dl dt::before{
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e1e1e1;
}
#PageCompany.pageIndex #Main #ContBox04 #ContSubBox04 dl dt span{
  padding: 0 10px 0 0;
  background-color: #fff;
  font-style: italic;
  font-size: 14px;
  font-weight: bold;
  color: #a4051a;
}
#PageCompany.pageIndex #Main #ContBox04 #ContSubBox04 dl dd span{
  font-size: 16px;
}
#PageCompany.pageIndex #Main #ContBox04 #ContSubBox04 dl dd:not(:last-of-type){
  margin-bottom: 25px;
}

/*
  初回限定ページ
  -------------------------------------*/
  #PageFirst.pageIndex img{
  	width: 100%;
  }
#PageFirst.pageIndex #Main .enTitle{
  color: #a4051a;
}

/*styleA*/
#PageFirst.pageIndex #Main #ContBox01.styleA{
  padding-top: 60px;
  padding-bottom: 20px;
  background-color: #f9e2eb;
}
#PageFirst.pageIndex #ContBox01.styleA #ContSubBox01 dt{
  position: relative;
  display: block;
  padding-bottom: 5px;
  width: 100%;
}
#PageFirst.pageIndex #ContBox01.styleA #ContSubBox01 .titleBand{
  position: absolute;
  top: 45px;
  left: 50%;
  display: block;
  width: 315px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
#PageFirst.pageIndex #ContBox01.styleA #ContSubBox01 .pushImg{
  display: block;
  width: 100%;
}
#PageFirst.pageIndex #ContBox01.styleA #ContSubBox01 .pushPrice{
  display: block;
  margin: 5px auto 0;
  width: 285px;
}
#PageFirst.pageIndex #ContBox01.styleA #ContSubBox01 .pushText{
  position: absolute;
  bottom: -73px;
  left: 50%;
  display: block;
  width: 100%;
  line-height: 1.5;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #a4051a;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
#PageFirst.pageIndex #ContBox01.styleA #ContSubBox01 dd{
  margin: 95px 0 0;
  padding: 0 15px;
  line-height: 1.85;
  text-align: justify;
  font-size: 16px;
}
#PageFirst.pageIndex #ContBox01.styleA #ContSubBox01 dd p{
  margin-bottom: 15px;
  line-height: 1.85;
}


/*styleB*/
#PageFirst.pageIndex #Main #ContBox01.styleB{
  display: none;

  /*padding-top: 60px;*/
  padding-bottom: 20px;
  background-color: #a4051a;
}
#PageFirst.pageIndex #ContBox01.styleB #ContSubBox01 dt{
  position: relative;
  display: block;
  padding-bottom: 5px;
  width: 100%;
}
#PageFirst.pageIndex #ContBox01.styleB #ContSubBox01 .titleBand{
  position: absolute;
  top: 45px;
  left: 50%;
  display: block;
  width: 315px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
#PageFirst.pageIndex #ContBox01.styleB #ContSubBox01 .pushImg{
  display: block;
  width: 100%;
}
#PageFirst.pageIndex #ContBox01.styleB #ContSubBox01 .pushPrice{
  display: block;
  margin: 5px auto 0;
  width: 285px;
}
#PageFirst.pageIndex #ContBox01.styleB #ContSubBox01 .pushText{
  position: absolute;
  bottom: -73px;
  left: 50%;
  display: block;
  width: 100%;
  line-height: 1.5;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #a4051a;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
#PageFirst.pageIndex #ContBox01.styleB #ContSubBox01 dd{
  margin: 95px 0 0;
  padding: 0 15px;
  line-height: 1.85;
  text-align: justify;
  font-size: 16px;
}
#PageFirst.pageIndex #ContBox01.styleB #ContSubBox01 dd p{
  margin-bottom: 15px;
  color: #fff;
  line-height: 1.85;
}


#PageFirst.pageIndex #Main #ContBox02{
	background-color: #f7eef0;
	padding: 40px 0 50px;
}
#PageFirst.pageIndex #Main #ContBox02 .hTitle{
	margin-bottom: 20px;
}
#PageFirst.pageIndex #Main #ContBox02 .enTitle{
	color: #f36e8d;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02{
	padding: 0 15px;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item{
	display: inline-block;
    margin-bottom: 15px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2%);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2%);
    border-radius: 8px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #f46082;
    position: relative;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .imgBox{
	border-top-left-radius: 7px;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(1) .imgBox{
	background: url(../img/contents/first/09_num_img01.png) no-repeat left top;
    background-size: 59px auto;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(2) .imgBox{
	background: url(../img/contents/first/09_num_img02.png) no-repeat left top;
    background-size: 59px auto;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(3) .imgBox{
	background: url(../img/contents/first/09_num_img03.png) no-repeat left top;
    background-size: 59px auto;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(4) .imgBox{
	background: url(../img/contents/first/09_num_img04.png) no-repeat left top;
    background-size: 59px auto;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(5) .imgBox{
	background: url(../img/contents/first/09_num_img05.png) no-repeat left top;
    background-size: 59px auto;
}
/*#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item::after{
	content: "";
	width: 59px;
	height: 59px;
	display: block;
    z-index: 10;
    position: absolute;
    top: -1px;
    left: -1px;
	border-top-left-radius: 8px;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(1)::after{
	background: url(../img/contents/first/09_num_img01.png) no-repeat left top;
    background-size: 59px auto;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(2)::after{
	background: url(../img/contents/first/09_num_img02.png) no-repeat left top;
    background-size: 59px auto;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(3)::after{
	background: url(../img/contents/first/09_num_img03.png) no-repeat left top;
    background-size: 59px auto;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(4)::after{
	background: url(../img/contents/first/09_num_img04.png) no-repeat left top;
    background-size: 59px auto;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(5)::after{
	background: url(../img/contents/first/09_num_img05.png) no-repeat left top;
    background-size: 59px auto;
}*/
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .thumb{
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	padding: 0 15px;
	/**/
	//max-width: 345px;
	display: block;
	margin: 0 auto;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(4) .thumb{
	padding-top: 8px;
	margin-bottom: -9px;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(5) .thumb{
	//height: 165px;
	//width: auto;
	padding-top: 8px;
	padding: 8px 89px 0;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .catch{
	font-size: 14px;
	text-align: center;
	margin: -2px 0 5px;
	line-height: 1.85;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(5) .catch{
	margin-top: -7px;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .title{
	font-size: 18px;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.1em;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .cost{
	text-align: center;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .cost .discountPrice{
	font-size: 14px;
	color: #888888;
	text-align: center;
	margin-bottom: 23px;
	text-decoration: line-through;
	letter-spacing: 0.1em;
	position: relative;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .cost .discountPrice::after{
	content: "";
	display: block;
	width: 13px;
	height: 12px;
	background: url(../img/contents/first/09_arrow_down.png) no-repeat center center;
	background-size: 13px auto;
	position: absolute;
	bottom: -13px;
	left: 50%;
	transform: translateX(-50%)
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .cost .labelBox{
	display: flex;
    margin: -5px 0 -3px -5px;
    flex-wrap: wrap;
    justify-content: center;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .cost .labelBox .label{
	margin: 5px 0 0 5px;
    padding: 1px .6333em 0 .79166em;
    background-color: #f46082;
    line-height: 24px;
    letter-spacing: .2em;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .cost .camPrice{
	font-size: 26px;
	font-weight: bold;
	font-family: 'Lato', sans-serif;
	color: #f46082;
	text-align: center;
	margin-bottom: 16px;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(4) .cost .camPrice{
	margin-bottom: 16px;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .cost .camPrice strong{
	font-size: 16px;
	font-weight: 700;
	font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .cost .camPrice span{
	font-size: 12px;
	font-weight: 700;
	font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .convLinkBtnWrap{
	width: 180px;
	margin: 0 auto 30px;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .convLinkBtn{
	width: 180px;
	text-align: center;
  display: inline-block;
  border-radius: 5px;
  padding: 13px 31px 13px 12px;
  background: url(../img/00_ico_blank.png) no-repeat right 22px center;
  background-color: #a4051a;
  background-size: 14px auto;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(4) .convLinkBtnWrap{
	display: none;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(4) .convLinkBtnWrap.inAdSet{
	display: block;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(5) .convLinkBtnWrap{
	display: none;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item:nth-of-type(5) .convLinkBtnWrap.inAdSet{
	display: block;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .convLinkBtnWrap.inAdSet{
	margin: 30px auto 45px;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .adSet .adSetBand{
	background-color: #222222;
	color: #fff;
	display: block;
	text-align: center;
	font-size: 12px;
	font-weight: 700;
	padding: 4px 0;
	letter-spacing: 0.2em;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .adSetList_item .itemInfo{
	font-size: 12px;
	margin-bottom: 5px;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .adSetList_item .itemInfo b{
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.1em;
	line-height: 1.85;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .adSetList_item .detailLinkBtn{
	width: 80px;
	float: right;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .adSetList_item .detailLinkBtn b{
	font-size: 12px;
	padding: 6px 0;
	display: block;
    border-radius: 5px;
    background-color: #a4051a;
    text-align: center;
    color: #fff;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .adSetList_item a{
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e1e1e1;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .adSetList_item:hover{
  text-decoration: none;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .adSetList_item figure{
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .adSetList_item img{
  width: 70px;
  display: inline-block;
}
#PageFirst.pageIndex #Main #ContBox02 #ContSubBox02 .relatedPosts_item .adSetList_item dl{
  display: inline-block;
  width: 100%;
  padding: 12px 15px 15px 10px;
}


#PageFirst.pageIndex #Main #ContBox03 {
	padding: 20px 0 0;
}
#PageFirst.pageIndex #Main #ContBox03 .enTitle{
	margin-bottom: 5px;
}
#PageFirst.pageIndex #Main #ContBox03 .hTitle{
	line-height: 1.5;
	margin-bottom: 25px;
}
#PageFirst.pageIndex #Main #ContBox03 figure{
  width: 170px;
  height: 170px;
  margin: 0 auto 28px;
}
#PageFirst.pageIndex #Main #ContBox03 figure img{
  width: 100%;
}
#PageFirst.pageIndex #Main #ContBox03 #ContSubBox03{
	padding: 0 15px;
}
#PageFirst.pageIndex #Main #ContBox03 #ContSubBox03 .voicePost_item_cont{
  border-bottom: 1px solid #e1e1e1;
}
#PageFirst.pageIndex #Main #ContBox03 #ContSubBox03 .voicePost_item_cont:first-of-type{
  border-top: 1px solid #e1e1e1;
}
#PageFirst.pageIndex #Main #ContBox03 #ContSubBox03 .voicePost_item_cont:last-of-type{
  border-bottom: none;
}
#PageFirst.pageIndex #Main #ContBox03 #ContSubBox03 .voicePost_item_cont p{
  display: block;
  padding: 23px 0 22px 45px;
  background: url(../img/contents/first/09_voice_img02.png) no-repeat left top 18px;
  background-size: 40px auto;
  line-height: 1.85;
}

#PageFirst.pageIndex #Main #ContBox04 {
	padding: 40px 0 0;
}
#PageFirst.pageIndex #Main #ContBox04 .enTitle{
	margin-bottom: 5px;
}
#PageFirst.pageIndex #Main #ContBox04 .hTitle{
	line-height: 1.5;
	margin-bottom: 20px;
}
#PageFirst.pageIndex #Main #ContBox04 #ContSubBox04{
	padding: 0 15px;
}
#PageFirst.pageIndex #Main #ContBox04 #ContSubBox04 .faqPost_item{
  border-bottom: 1px solid #e1e1e1;
}
#PageFirst.pageIndex #Main #ContBox04 #ContSubBox04 .faqPost_item:first-of-type{
  border-top: 1px solid #e1e1e1;
}
#PageFirst.pageIndex #Main #ContBox04 #ContSubBox04 .faqPost_item:last-of-type{
  border-bottom: none;
}
#PageFirst.pageIndex #Main #ContBox04 #ContSubBox04 .faqPost_item_cont dt{
  display: block;
  padding: 24px 0 10px 45px;
  background: url(../img/00_ico_subfaq.png) no-repeat left top 21px;
  background-size: 35px auto;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.85;
}
#PageFirst.pageIndex #Main #ContBox04 #ContSubBox04 .faqPost_item_cont dd{
  display: block;
  padding: 10px 0 23px 45px;
  background: url(../img/00_ico_subfaq_ans.png) no-repeat left top 4px;
  background-size: 35px auto;
}
#PageFirst.pageIndex #Main #ContBox04 #ContSubBox04 .faqPost_item_cont p{
  font-size: 16px;
  margin-bottom: 15px;
}
#PageFirst.pageIndex #Main #ContBox04 #ContSubBox04 .faqPost_item_cont ul li{
  position: relative;
  padding-left: 15px;
  line-height: 1.85;
  font-size: 16px;
}
#PageFirst.pageIndex #Main #ContBox04 #ContSubBox04 .faqPost_item_cont ul li::before {
  content: "";
  position: absolute;
  top: 12px;
  left: 0;
  display: block;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: #a4051a;
}
#PageFirst.pageIndex #Main #ContBox04 #ContSubBox04 .faqPost_item_cont ol li{
  line-height: 1.85;
  font-size: 16px;
  list-style: none;
}

#PageFirst.pageIndex #Main .linkSection{
  background-color: #f0f0f0;
  padding: 30px 15px 20px;
}
#PageFirst.pageIndex #Main .linkSection a{
  display: block;
  margin-bottom: 10px;
  padding: 20px 15px 20px 90px;
  font-size: 16px;
  font-weight: 700;
}
#PageFirst.pageIndex #Main .linkSection .hukidashi{
	max-width: 349px;
	display: block;
	margin: 0 auto;
	margin-bottom: 5px;
}
#PageFirst.pageIndex #Main .linkSection .blogLink_voice a{
  background: url(../img/00_ico_voice.png) no-repeat left 15px center, url(../img/00_arrow02_red.png) no-repeat right 15px center;
  background-size: 59px auto, 10px auto;
  background-color: #fff;
}
#PageFirst.pageIndex #Main .linkSection .blogLink_faq a{
  background: url(../img/00_ico_faq.png) no-repeat left 15px center, url(../img/00_arrow02_red.png) no-repeat right 15px center;
  background-size: 53px auto, 10px auto;
  background-color: #fff;
}


/*
  404ページ
  -------------------------------------*/
#Page404.pageIndex #MainImg #MainImgInner {
  width: 100%;
  background: url("../img/contents/lower_title_04.jpg") no-repeat center center;
  background-size: cover;
}

#Page404.pageIndex #Main .innerBasic {
  padding: 25px;
}

#Page404.pageIndex #Main p{
  text-align: center;
  margin: 30px;
}

#Page404.pageIndex #Main .linkBtn a{
    display: block;
    margin: 30px auto 0;
    border-radius: 5px;
    width: 225px;
    height: 60px;
    background-color: #a4051a;
    line-height: 60px;
    text-align: center;
    text-decoration: none;
    letter-spacing: .05em;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
}





@media print,
screen and (min-width: 768px) {

  /*
  トップページ
  -------------------------------------*/

  #Page.pageIndex #MainImg #MainImgInner{
    background-color: #f0f0f0;
  }
  #Page.pageIndex #MainImg #MainImgInner li{
    height: 600px;
  }
  #Page.pageIndex #MainImg #MainImgInner li.slide01 {
    background: url(../img/contents/00_mv_pc01.jpg) no-repeat center center;
    background-size: 1280px auto;
  }
  #Page.pageIndex #MainImg #MainImgInner li.slide02{
    background: url(../img/contents/00_mv_pc02.jpg) no-repeat center center;
    background-size: 1280px auto;
  }
  #Page.pageIndex #MainImg #MainImgInner li.slide03{
    background: url(../img/contents/00_mv_pc03.jpg) no-repeat center center;
    background-size: 1280px auto;
  }
  #Page.pageIndex #MainImg #MainImgInner li.slide01 .slideText{
    bottom: 50%;
    left: 0;
    transform: translateY(50%);
  }
  #Page.pageIndex #MainImg #MainImgInner li.slide02 .slideText{
    top: 53px;
    right: 0;
  }
  #Page.pageIndex #MainImg #MainImgInner li.slide03 .slideText{
    bottom: 50%;
    left: 0;
    transform: translateY(50%);
  }
  #Page.pageIndex #MainImg #MainImgInner li .slideText h2{
    font-size: 44px;
  }
  #Page.pageIndex #MainImg #MainImgInner li .slideText .linkBtn{
    margin-top: 15px;
  }
  #Page.pageIndex #MainImg #MainImgInner .tSlider li img{
    display: none;
  }

  /*キャンペーンスライド*/
  #Page.pageIndex #MainImg #MainImgInner li.slide03.campaignSlide{
    background: url(../img/contents/00_mv_pc04.jpg) no-repeat center center;
    background-size: 1280px auto;
  }
  #Page.pageIndex #MainImg #MainImgInner li.slide03.campaignSlide .slideText h2{
    font-size: 36px;
    line-height: 1.4;
    margin-bottom: 20px;
  }
  #Page.pageIndex #MainImg #MainImgInner li.slide03.campaignSlide .slideText .camLabel{
    width: 217px;
    height: 217px;
    display: block;
    margin: 0 auto;
  }
  #Page.pageIndex #MainImg #MainImgInner li.slide03.campaignSlide .slideText .linkBtn{
    margin-top: 30px;
  }
/*　//キャンペーンスライド*/




  /*
  キャンペーン情報ブログ　共通
  -------------------------------------*/
  #PageCampaign .innerBasic{
    padding: 0;
  }
  #PageCampaign .menu_sp .contactList.innerBasic{
    padding: 40px 15px;
  }


  /*
  キャンペーン情報ブログ　詳細ページ
  -------------------------------------*/
  #PageCampaign.pageEntry #Main #ContBox01 .header,
  #PageCampaign.pageEntry #Main #ContBox01 .note p,
  #PageCampaign.pageEntry #Main #ContBox01 .infoBox_cont {
    max-width: 800px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
  #PageCampaign.pageEntry #Main #ContBox01 .slider_thumbs {
    /*max-width: 970px;*/
    margin: 0 auto;
  }

  /*
  お問い合わせ　共通
  -------------------------------------*/
  #PageContact .innerBasic{
    padding: 0 15px;
  }
  #PageContact .redBox{
    pointer-events: none;
  }

  /*
  企業情報
  -------------------------------------*/
  #PageCompany.pageIndex #Main #ContBox03 table tr.tel td a{
    pointer-events: none;
  }

  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
  }

  /*
  Story blog
  -------------------------------------*/
  #PageBlog.pageIndex {
  }

  /*
  カテゴリアーカイブ
  -------------------------------------*/
  #PageBlog.pageCategory {
  }

  /*
  月別アーカイブ
  -------------------------------------*/
  #PageBlog.pageArchive {
  }

  /*
  記事詳細ページ
  -------------------------------------*/
  #PageBlog.pageEntry {
  }

  /*
  お問い合わせ
  -------------------------------------*/
  #PageInquiry.pageIndex {
  }

  /*
  確認画面
  -------------------------------------*/
  #PageInquiry.pageConfirm {
  }

  /*
  送信完了画面
  -------------------------------------*/
  #PageInquiry.pageThanks {
  }

  /*
  プライバシーポリシー
  -------------------------------------*/
  #Page.pagePolicy {
  }


}
@media print,
screen and (max-width: 767px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
  }

  /*
  Story blog
  -------------------------------------*/
  #PageBlog.pageIndex {
  }

  /*
  カテゴリアーカイブ
  -------------------------------------*/
  #PageBlog.pageCategory {
  }

  /*
  月別アーカイブ
  -------------------------------------*/
  #PageBlog.pageArchive {
  }

  /*
  記事詳細ページ
  -------------------------------------*/
  #PageBlog.pageEntry {
  }

  /*
  お問い合わせ
  -------------------------------------*/
  #PageInquiry.pageIndex {
  }

  /*
  確認画面
  -------------------------------------*/
  #PageInquiry.pageConfirm {
  }

  /*
  送信完了画面
  -------------------------------------*/
  #PageInquiry.pageThanks {
  }

  /*
  プライバシーポリシー
  -------------------------------------*/
  #Page.pagePolicy {
  }


}

@media print,
screen and (max-width: 374px) {
  .menu_sp .contactList .netOrder .contactBtn span{
    letter-spacing: -0.04em;
  }
  .menu_sp .contactList dt{
    letter-spacing: .05em;
  }
  #PageContact.pageIndex #Main #ContBox01 .contactList li dt{
    letter-spacing: .05em;
  }
}